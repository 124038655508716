/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
/* eslint-disable new-cap */
// /* eslint-disable import/no-extraneous-dependencies */

import isArray from 'lodash/isArray';
import uniqueId from 'lodash/uniqueId';
import isEmpty from 'lodash/isEmpty';
import CryptoJS from 'crypto-js';
import {
  format,
  parse,
  intervalToDuration,
  addDays,
  isValid,
  isSameDay,
  set,
  differenceInMinutes,
  startOfDay,
  addMinutes,
} from 'date-fns';
import { convertMinutesToTime } from './dateUtils';
import globalConst from '../constants';
import { serializeParamsToQueryString } from './serialize';

/**
 * Search for a multiple parameters
 */
export const includesSome = (array, values) => values.some((value) => array.includes(value));

/**
 * Remove white spaces from start and end
 */
export const trimString = (string = '') => (string && string.replace(/(^[\s]+|[\s]+$)/g, '')) || '';

/**
 * Rounds up the number up to 2 places after the decimal.
 */
export const parseFloatFunc = (str = '', val = 2) => {
  if (!str) {
    return '';
  }

  const prepareNumber = parseFloat(str).toFixed(val);
  return Number(prepareNumber);
};

/**
 * Hash emails
 */
export const hashEmail = (email) => {
  const hash = CryptoJS.SHA256(email);
  return hash.toString();
};

/**
 * Get first two symbols in upper case
 */
export const getTwoUpperSymbol = (string = '') => (string && string.substring(0, 2).toUpperCase()) || '';

export const isMobile = (function () {
  const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
  return Boolean(
    userAgent.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    )
  );
}());

export const handleScanLabel = (status, tr) => {
  switch (status) {
    case 'ok':
      return tr.common.virusFree;
    case 'found':
    case 'warning':
      return tr.common.virusAlert;
    case 'pending':
      return tr.common.scanning;
    case 'missed':
      return tr.common.missed;
    default:
      break;
  }
};

export const handleScanStatus = (status) => {
  switch (status) {
    case 'ok':
      return 'checkmark';
    case 'found':
    case 'warning':
      return 'alert';
    case 'pending':
      return 'sync';
    case 'missed':
      return 'eyeClose';
    default:
      break;
  }
};

export const handleScanColor = (status) => {
  switch (status) {
    case 'ok':
      return '#007D8B';
    case 'found':
    case 'warning':
      return '#B70C3B';
    case 'pending':
    case 'missed':
      return '#4DCDC4';
    default:
      break;
  }
};

export const createPreview = (obj) => {
  if (isEmpty(obj)) {
    return [];
  }
  let sequence = String(obj.next_value);
  if (sequence.length < 5) {
    sequence = new Array(6 - sequence.length).join('0') + sequence;
  }
  const preview = [obj.entity, sequence];
  if (obj.year) {
    preview.unshift(new Date().getFullYear());
  }
  return preview;
};

/**
 * Convert string camelCase to snake_case
 */
export const convertToSnakeCase = (string = '') => {
  if (!string) {
    return '';
  }

  return string.replace(/\.?([A-Z]+)/g, (x, y) => `_${y.toLowerCase()}`)
    .replace(/^_/, '');
};

/**
 * Preparing parameters for manipulate at planning page
 */

export const paramsWithFiltersForShifts = (appliedFilters, workerView = false) => {
  const params = {};
  const workersFilters = isEmpty(appliedFilters.byWorkers) ? appliedFilters.byGigWorkers : appliedFilters.byWorkers;
  params.by_statuses = appliedFilters.byStatuses;
  params.by_organizations = appliedFilters.byClients;
  params.by_locations = appliedFilters.byLocations;
  params.by_roles = appliedFilters.byRoles;
  params.by_workers = workersFilters.map((worker) => worker.id);
  params.by_gig_category = appliedFilters.byShiftTypes;

  if (!isEmpty(appliedFilters.byGroups)) {
    params.by_organizations_groups = appliedFilters.byGroups;
  }

  if (!isEmpty(appliedFilters.byGroups)) {
    params.by_organizations_groups = appliedFilters.byGroups;
  }

  if (appliedFilters.byBookmark) {
    params.bookmarked = appliedFilters.byBookmark;
  }

  if (appliedFilters.byComments) {
    params.with_comments = appliedFilters.byComments;
  }

  if (appliedFilters.byProject) {
    params.by_project = appliedFilters.byProject;
  }

  if (!isEmpty(appliedFilters.byAttributes)) {
    params.by_custom_attrs = [];
    params.by_string_custom_attrs = [];

    Object.keys(appliedFilters.byAttributes).forEach((key) => {
      const arr = {};
      arr.id = key;
      arr.title = appliedFilters.byAttributes[key].title;

      if (appliedFilters.byAttributes[key].input_type === 'multiselect') {
        appliedFilters.byAttributes[key].title.forEach((attr) => {
          const attrArr = {};
          attrArr.id = key;
          attrArr.title = attr.title;
          params.by_custom_attrs.push(attrArr);
        });
        return;
      }

      if (appliedFilters.byAttributes[key].input_type === 'textarea') {
        params.by_string_custom_attrs.push(arr);
        return;
      }

      params.by_custom_attrs.push(arr);
    });
  }

  if (!isEmpty(appliedFilters.byOrgAttributes)) {
    params.by_org_custom_attrs = [];
    params.by_org_string_custom_attrs = [];

    Object.keys(appliedFilters.byOrgAttributes).forEach((key) => {
      const arr = {};
      arr.id = key;
      arr.title = appliedFilters.byOrgAttributes[key].title;

      if (appliedFilters.byOrgAttributes[key].input_type === 'multiselect') {
        appliedFilters.byOrgAttributes[key].title.forEach((attr) => {
          const attrArr = {};
          attrArr.id = key;
          attrArr.title = attr.title;
          params.by_org_custom_attrs.push(attrArr);
        });
        return;
      }

      if (appliedFilters.byOrgAttributes[key].input_type === 'textarea') {
        params.by_org_string_custom_attrs.push(arr);
        return;
      }

      params.by_org_custom_attrs.push(arr);
    });
  }

  if (!isEmpty(appliedFilters.byWorkerAttributes) && workerView) {
    params.by_workers_custom_worker_attrs = [];
    params.by_workers_string_custom_worker_attrs = [];

    Object.keys(appliedFilters.byWorkerAttributes).forEach((key) => {
      const arr = {};
      arr.id = key;
      arr.title = appliedFilters.byWorkerAttributes[key].title;

      if (appliedFilters.byWorkerAttributes[key].input_type === 'multiselect') {
        appliedFilters.byWorkerAttributes[key].title.forEach((attr) => {
          const attrArr = {};
          attrArr.id = key;
          attrArr.title = attr.title;
          params.by_workers_custom_worker_attrs.push(attrArr);
        });
        return;
      }

      if (appliedFilters.byWorkerAttributes[key].input_type === 'textarea') {
        params.by_workers_string_custom_worker_attrs.push(arr);
        return;
      }

      params.by_workers_custom_worker_attrs.push(arr);
    });
  }

  return params;
};

/**
 * use delay function
 * @param {number} ms defaul delay
 */
export const handleDelay = (ms = 1000) => new Promise((resolve) => { setTimeout(resolve, ms); });

/**
 * Check item in array
 * @param {array} arr list of items
 * @param {*} item
 */
export const checkItemInArray = (arr, item) => arr.indexOf(item) > -1;
export const findItemsInArrayOfObj = (arr, itemsArr) => arr.filter((item) => checkItemInArray(itemsArr, item.id));

export const getIdListFromArrayObj = (items = []) => items.map((item) => item.id);

export const insertAtCursor = (myField, myValue) => {
  const fieldValue = myField.value;

  if (myField.selectionStart || myField.selectionStart === 0) {
    const startPos = myField.selectionStart;
    const endPos = myField.selectionEnd;

    const startText = fieldValue.substring(0, startPos);
    const endText = fieldValue.substring(endPos, fieldValue.length);

    const pos = startText.length + myValue.length + 1;
    return {
      text: `${startText} ${myValue} ${endText}`,
      position: pos,
    };
  }

  const text = `${fieldValue} ${myValue}`;
  return {
    text,
    position: text.length,
  };
};

export const getPagerList = (currentPage, totalPages) => {
  const SHOW_PAGES = 5;
  const MORE_STR = 'more';

  const pagesList = [...Array(totalPages).keys()]
    .map((index) => ({
      id: index + 1,
      page: index + 1,
    }));

  if (totalPages <= SHOW_PAGES) {
    return pagesList;
  }

  let startPage = (currentPage - 2) <= 1 ? 1 : (currentPage - 2);
  if (totalPages - currentPage <= 1) {
    startPage = (totalPages - SHOW_PAGES) + 1;
  }

  const endPage = (currentPage + 2) <= SHOW_PAGES ? SHOW_PAGES : currentPage + 2;
  // remove items from end, add dots and last page
  if ((endPage + 1) < totalPages) {
    pagesList.splice(endPage, totalPages);

    pagesList.push({
      id: endPage + 1,
      page: MORE_STR,
    });
    pagesList.push({
      id: endPage + 2,
      page: totalPages,
    });
  }

  // remove items from start, add dots adn first page
  if (startPage > 2) {
    const startIndex = startPage - 1;

    pagesList.splice(0, startIndex);
    pagesList.unshift({
      id: startIndex,
      page: MORE_STR,
    });
    pagesList.unshift({
      id: startIndex - 1,
      page: 1,
    });
  }

  return pagesList;
};

export const fillTemplate = (obj, template) => {
  const toString = Object.keys(obj)
    .map((item) => `{{${item}}}`)
    .join('|');
  const regexp = new RegExp(toString, 'gi');

  return template.replace(regexp, (key) => {
    const fixedKey = key.replace(/[{()}]/g, '');
    return obj[fixedKey];
  });
};

export const getLangFromCountryCode = (code = 'NL') => {
  if (code === 'NL') {
    return 'nl';
  }

  return 'en';
};

export const getDateTypesList = (tr) => ([
  { value: globalConst.DAY_STR, label: tr.createGig.daily },
  { value: globalConst.WEEKDAY_STR, label: tr.createGig.weekday },
  { value: globalConst.WEEK_STR, label: tr.createGig.weekly },
]);

export const getRepeatTypeOptions = (tr) => ([
  {
    value: globalConst.DAY_STR,
    label: tr.createGig.daily,
  },
  {
    value: globalConst.WEEK_STR,
    label: tr.createGig.weekly,
  },
  {
    value: globalConst.BIWEEKLY_STR,
    label: tr.createGig.biweekly,
  },
  {
    value: globalConst.WEEKDAY_STR,
    label: tr.createGig.weekday,
  },
  {
    value: globalConst.THREE_WEEKS_STR,
    label: tr.createGig.fewWeeks.replace('$amount', 3),
  },
  {
    value: globalConst.FOUR_WEEKS_STR,
    label: tr.createGig.fewWeeks.replace('$amount', 4),
  },
  {
    value: globalConst.FIVE_WEEKS_STR,
    label: tr.createGig.fewWeeks.replace('$amount', 5),
  },
]);

export const getRepeatAvailabilityTypeOptions = (tr) => ([
  {
    value: globalConst.DAY_STR,
    label: tr.createGig.daily,
  },
  {
    value: globalConst.WEEK_STR,
    label: tr.createGig.weekly,
  }
]);

export const getRepeatTypeOption = (value, tr) => {
  const options = {
    [globalConst.DAY_STR]: {
      value: globalConst.DAY_STR,
      label: tr.createGig.daily,
    },
    [globalConst.WEEK_STR]: {
      value: globalConst.WEEK_STR,
      label: tr.createGig.weekly,
    },
    [globalConst.BIWEEKLY_STR]: {
      value: globalConst.BIWEEKLY_STR,
      label: tr.createGig.biweekly,
    },
    [globalConst.WEEKDAY_STR]: {
      value: globalConst.WEEKDAY_STR,
      label: tr.createGig.weekday,
    },
    [globalConst.THREE_WEEKS_STR]: {
      value: globalConst.THREE_WEEKS_STR,
      label: tr.createGig.fewWeeks.replace('$amount', 3),
    },
    [globalConst.FOUR_WEEKS_STR]: {
      value: globalConst.FOUR_WEEKS_STR,
      label: tr.createGig.fewWeeks.replace('$amount', 4),
    },
    [globalConst.FIVE_WEEKS_STR]: {
      value: globalConst.FIVE_WEEKS_STR,
      label: tr.createGig.fewWeeks.replace('$amount', 5),
    },
  };

  return options[value];
};

export const getPercentageOptions = (lowerBound = 101, amount = 100) => {
  const array = Array.from(Array(amount).keys(), (x) => {
    const value = x + lowerBound;
    return ({
      value,
      label: `${value}%`,
    });
  });

  return array;
};

export const getSettingsTitle = (type = globalConst.GIG_TYPE, tr = {}, options = {}) => {
  let title = '';

  switch (type) {
    case globalConst.ORGANIZATION_STR:
      title = tr.common.settingsOrganization;
      break;
    case globalConst.GIG_TYPE:
      title = tr.common.settingsShift;
      break;
    case globalConst.WORKER_STR:
      title = tr.common.settingsWorker;
      break;
    case globalConst.ORT_STR:
      title = options.featureIsBilling ? tr.common.billingAgreement : tr.common.ortSettings;
      break;
    case globalConst.SHIFT_TYPES_STR:
      title = tr.common.settingsShift;
      break;
    case globalConst.EXPENSES_STR:
      title = tr.common.settingsWorklog;
      break;
    default:
      title = tr.common.settings;
  }

  return title;
};

/**
 * Create columns list for agenda
 * @param {object} tr translations
 */
export const getAgendaColumnsList = (tr) => [
  {
    id: 'duration',
    title: tr.common.duration,
  },
  {
    id: 'organization',
    title: tr.common.organization,
  },
  {
    id: 'location',
    title: tr.common.location,
  },
  {
    id: 'role',
    title: tr.common.role,
  },
  {
    id: 'workers',
    title: tr.common.workers,
  },
  {
    id: 'description',
    title: tr.common.description,
  },
  {
    id: 'bookmark',
    title: tr.common.bookmark,
  },
];

export const getWorkerColumnsList = (tr) => [
  {
    id: 'email',
    title: tr.form.email,
  },
  {
    id: 'roles',
    title: tr.common.roles,
  },
];
/**
 * Create columns list for report
 * @param {object} tr translations
 */
export const getReportColumnsList = (tr, options) => {
  const columnsList = [
    {
      id: 'gig_id',
      title: 'ID',
    },
    {
      id: 'gig_bookmark',
      title: tr.common.bookmark,
    },
    {
      id: 'gig_updated_at',
      title: tr.common.lastEdit,
    },
    {
      id: 'gig_date',
      title: tr.common.shiftDate,
    },
    {
      id: 'gig_date_week_day',
      title: tr.common.weekday,
    },
    {
      id: 'worker_id',
      title: tr.common.workerId,
    },
    {
      id: 'worker_full_name',
      title: tr.common.worker,
    },
    {
      id: 'worker_email',
      title: tr.common.workerEmail,
    },
    {
      id: 'gig_role_title',
      title: tr.common.role,
    },
    {
      id: 'gig_created_by',
      title: tr.common.planner,
    },
    {
      id: 'gig_organization_id',
      title: tr.common.organizationId,
    },
    {
      id: 'gig_organization_title',
      title: tr.common.organization,
    },
    {
      id: 'gig_title',
      title: tr.createGig.gigName,
    },
    {
      id: 'gig_location_address',
      title: tr.common.location,
    },
    {
      id: 'gig_location_description',
      title: tr.common.locationDescription,
    },
    {
      id: 'gig_status',
      title: tr.createGig.shitStatus,
    },
    {
      id: 'removal_reason',
      title: tr.common.removalReason
    },
    {
      id: 'gig_description',
      title: tr.form.description,
    },
    {
      id: 'gig_hidden_description',
      title: tr.form.descriptionHidden,
    },
    {
      id: 'gig_worker_status',
      title: tr.common.workerStatus,
    },
    {
      id: 'gig_worker_accepted_at',
      title: tr.common.workerAcceptedAt,
    },
    {
      id: 'gig_workers_needed',
      title: tr.common.workersNeeded,
    },
    {
      id: 'gig_start_at',
      title: tr.createGig.startTime,
    },
    {
      id: 'gig_finish_at',
      title: tr.createGig.endTime,
    },
    {
      id: 'gig_duration',
      title: tr.common.duration,
    },
    {
      id: 'gig_created_at',
      title: tr.common.createdAt,
    },
    {
      id: 'worklog',
      title: tr.common.timeTracking,
    },
  ];

  if (options.featureIsGigCategorySettings) {
    columnsList.push(
      {
        id: 'shift_type',
        title: tr.common.shiftType,
      }
    );
  }
  return columnsList;
};

/**
 * Prepare status list
 * @param {object} tr
 */
export const getStatusListObj = (tr = {}) => [
  {
    value: globalConst.ACTIVE_STATUS,
    label: tr.statuses.staffed,
  },
  {
    value: globalConst.DRAFT_STATUS,
    label: tr.statuses.unpublished,
  },
  {
    value: globalConst.PENDING_WORKERS_STATUS,
    label: tr.statuses.pending,
  },
  {
    value: globalConst.PARTIALLY_PENDING_STATUS,
    label: tr.statuses.partially_pending,
  },
  {
    value: globalConst.PENDING_APPROVAL_STATUS,
    label: tr.statuses.pending_approval,
  },
  {
    value: globalConst.ARCHIVED_STATUS,
    label: tr.statuses.archived,
  },
];

export const getTimeStatusListObj = (tr) => [
  {
    value: globalConst.APPROVED_STATUS,
    label: tr.statuses.approved,
  },
  {
    value: globalConst.CORRECT_STATUS,
    label: tr.statuses.correct,
  },
  {
    value: globalConst.INCORRECT_STATUS,
    label: tr.statuses.deviation,
  },
  {
    value: globalConst.DRAFT_STATUS,
    label: tr.statuses.not_logged,
  },
];

export const getStatusesList = () => [
  globalConst.ACTIVE_STATUS,
  globalConst.DRAFT_STATUS,
  globalConst.PENDING_WORKERS_STATUS,
  globalConst.PARTIALLY_PENDING_STATUS,
  globalConst.PENDING_APPROVAL_STATUS,
  globalConst.ARCHIVED_STATUS,
];

export const getWorklogStatusesList = () => [
  globalConst.APPROVED_STATUS,
  globalConst.CORRECT_STATUS,
  globalConst.INCORRECT_STATUS,
  globalConst.DRAFT_STATUS,
];

export const getStatusesFromUrl = (statuses = []) => {
  if (typeof statuses === 'string') {
    const isExist = getStatusesList().indexOf(statuses) !== -1;
    return isExist ? [statuses] : [];
  }

  if (isArray(statuses)) {
    const filtered = statuses.filter((item) => getStatusesList().indexOf(item) !== -1);

    // use Set for remove duplicates
    return [
      ...new Set(filtered)
    ];
  }

  return [];
};

export const getTimeStatusFromUrl = (statuses) => {
  if (!statuses) {
    return [];
  }

  if (typeof statuses === 'string') {
    const isExist = getWorklogStatusesList().indexOf(statuses) !== -1;
    return isExist ? [statuses] : [];
  }

  if (isArray(statuses)) {
    const filtered = statuses.filter((item) => getWorklogStatusesList().indexOf(item) !== -1);

    // use Set for remove duplicates
    return [
      ...new Set(filtered)
    ];
  }

  return [];
};

export const getStringFromUrl = (searchString) => {
  if (!searchString) {
    return '';
  }

  if (typeof searchString === 'string') {
    return searchString;
  }

  return '';
};

export const getBoolFromUrl = (boolString = 'false') => (boolString === 'true') && true;

/**
 * Get number from from url
 * @param {string} value
 */
export const getNumberFromUrl = (value = '') => {
  if (!value) {
    return '';
  }

  if (typeof value !== 'string') {
    return '';
  }

  const id = parseInt(value, globalConst.RADIX_DECIMAL);
  if (!id) {
    return '';
  }

  return id;
};

/**
 * Get view string from url
 * @param {string} view
 */
export const getViewFromUrl = (view) => {
  if (!view
    || typeof view !== 'string'
    || !checkItemInArray(globalConst.CALENDAR_SHORT_VIEW, view)
  ) {
    return globalConst.WEEK_STR;
  }

  return view;
};

/**
 * Get view string from url
 * @param {string} view
 */
export const getCalendarViewFromUrl = (view) => {
  if (!view
    || typeof view !== 'string'
    || !checkItemInArray(globalConst.CALENDAR_ALL_VIEW, view)
  ) {
    return globalConst.WEEK_STR;
  }

  return view;
};

export const getLastPath = (pathname) => pathname.split('/').pop();
export const getCurrentPath = (pathname) => pathname.split('/')[2];
export const getLangPath = (pathname) => pathname.split('/')[1];

export const convertArrayToObject = (array) => array.reduce((obj, item) => ({ ...obj, ...item }), {});
export const convertArrayToObjectID = (array) => array.reduce((obj, item) => ({ ...obj, [item.id]: item }), {});

/**
 * Formatted gig for worker calendar
 * @param {array} items
 */
export const availabilityGigsFormatted = (items, gigStatus = '') => {
  const prepareItems = [];
  items.forEach((item) => {
    const start = new Date(`${item.date}T${item.start_at}`);
    const end = new Date(`${item.date}T${item.finish_at}`);

    const startTime = new Date(`2000-07-21T${item.start_at}`); // add random date in the middle of the year to prevent winter(summer) time change issue
    const finishTime = item.night_date ? new Date('2000-07-22T00:00') : new Date(`2000-07-21T${item.finish_at}`); // add random date in the middle of the year to prevent winter(summer) time change issue
    const durationInMinutes = differenceInMinutes(finishTime, startTime);

    const prepareItem = {
      id: item.id,
      key: item.id,
      parent_id: item.parent_id || '',
      title: gigStatus || item.title,
      status: gigStatus || item.status,
      start,
      end,
      durationInMinutes,
    };

    if (item.night) {
      const nightStart = new Date(`${item.night_date}T${item.night_start_at}`);
      const nightEnd = new Date(`${item.night_date}T${item.night_finish_at}`);

      const nightStartTime = new Date(`2000-07-21T${item.night_start_at}`); // add random date in the middle of the year to prevent winter(summer) time change issue
      const nightFinishTime = new Date(`2000-07-21T${item.night_finish_at}`); // add random date in the middle of the year to prevent winter(summer) time change issue
      const nightDurationInMinutes = differenceInMinutes(nightFinishTime, nightStartTime);

      prepareItems.push({
        ...prepareItem,
        key: `${item.id}_night`,
        start: nightStart,
        end: nightEnd,
        durationInMinutes: nightDurationInMinutes,
      });
    }

    prepareItems.push(prepareItem);
  });

  return prepareItems;
};

/**
 * Formatted days off for calendar
 * @param {array} items
 */
export const availabilityTimesOffFormatted = (items) => items.map((item) => {
  const startTime = item.all_day ? '00:00' : item.start_at;
  const finishTime = item.all_day ? '23:59' : item.finish_at;

  const start = new Date(`${item.date}T${startTime}`);
  const end = new Date(`${item.date}T${finishTime}`);
  const durationInMinutes = differenceInMinutes(end, start);

  return ({
    id: item.id,
    parent_id: '',
    title: globalConst.DAY_OFF_STATUS,
    status: globalConst.DAY_OFF_STATUS,
    recurrent: item.recurrent,
    reason: item.reason || '',
    unavailabilityId: item.unavailabilityId ? +item.unavailabilityId : '',
    canUpdate: item.can_update,
    start,
    end,
    durationInMinutes,
  });
});

export const groupCustomAttributes = (items = []) => {
  if (!items) {
    return [];
  }

  const regularAttributes = items.filter((attr) => attr.custom_attribute.input_type !== 'multiselect');
  const multiselectAttributes = items.filter((attr) => attr.custom_attribute.input_type === 'multiselect');
  const groupedByMultiselectAttributes = multiselectAttributes.reduce((acc, attr) => {
    const { id } = attr.custom_attribute;

    if (acc[id]) {
      acc[id].title.push(attr.title);
    } else {
      acc[id] = { ...attr, title: [attr.title] };
    }

    return acc;
  }, {});

  Object.entries(groupedByMultiselectAttributes).forEach(([key, value]) => {
    regularAttributes.push({ id: key, ...value });
  });

  return regularAttributes;
};

/**
 * Format custom attributes
 * @param {array} items custom attributes
 */
export const formatCustomAttributes = (items = []) => {
  const attributes = {};
  const groupedAttributes = groupCustomAttributes(items);

  groupedAttributes.forEach((attr) => {
    if (attr.custom_attribute.input_type === 'select') {
      const value = attr.custom_attribute.options.indexOf(attr.title);

      attributes[attr.custom_attribute.id] = {
        value,
        title: attr.title,
        id: attr.id,
      };
      return;
    }

    if (attr.custom_attribute.input_type === 'checkbox') {
      attributes[attr.custom_attribute.id] = {
        value: attr.title === '1' || false,
        title: attr.title,
        id: attr.id,
      };
      return;
    }

    if (attr.custom_attribute.input_type === 'multiselect') {
      const title = attr.title.map((item) => ({
        title: item,
        value: attr.custom_attribute.options.indexOf(item),
        propertyId: attr.custom_attribute.id,
      }));

      attributes[attr.custom_attribute.id] = {
        value: title,
        title,
        id: attr.id,
      };
      return;
    }

    attributes[attr.custom_attribute.id] = {
      title: attr.title,
      value: attr.title,
      id: attr.id,
    };
  });

  return attributes;
};

/**
 * Normalize minutes
 */
export const normalizeMinutes = (time = 0) => {
  if (!time || (typeof time !== 'number') || time > 59) {
    return '00';
  }

  if (time > 0 && time < 10) {
    return `0${time}`;
  }

  return `${time}`;
};

/**
 * Normalize hours
 */
export const normalizeHours = (time = 0) => {
  if (!time || (typeof time !== 'number') || time > 23) {
    return '00';
  }

  if (time > 0 && time < 10) {
    return `0${time}`;
  }

  return `${time}`;
};

/**
 * Prepare worklog to form
 * @param {object} obj worklog
 */

export const prepareWorklogToForm = (obj = {}) => {
  // get duration hours, minutes
  const [hours, minutes] = obj.gig?.duration.split(':') || ['0', '00'];
  const planedHours = parseInt(hours, globalConst.RADIX_DECIMAL);
  const planedMinutes = parseInt(minutes, globalConst.RADIX_DECIMAL);

  // get break duration, convert to Date
  const breakHours = obj.timebreak?.hours || 0;
  const breakMinutes = obj.timebreak?.minutes || 0;
  const getHours = normalizeHours(breakHours);
  const getMinutes = normalizeMinutes(breakMinutes);
  const breakTime = `${getHours}:${getMinutes}`;
  const breakMoment = new Date(`${obj.gig.date}T${breakTime}`);

  const startTime = new Date(`2000-07-21T${obj.start_at}`); // add random date in the middle of the year to prevent winter(summer) time change issue
  const finishTime = new Date(`2000-07-21T${obj.finish_at}`); // add random date in the middle of the year to prevent winter(summer) time change issue
  const diff = differenceInMinutes(finishTime, startTime);
  const endTime = diff <= 0 ? addDays(finishTime, 1) : finishTime;

  const sumPlanedMinutes = planedHours * 60 + planedMinutes;
  const sumActualMinutes = differenceInMinutes(endTime, startTime);
  const sumBreakMinutes = breakHours * 60 + breakMinutes;

  const deviationMinutes = sumActualMinutes - sumPlanedMinutes - sumBreakMinutes;
  const deviation = convertMinutesToTime(deviationMinutes);

  const totalDurationMinutes = sumActualMinutes - sumBreakMinutes;
  const totalDuration = intervalToDuration({ start: 0, end: totalDurationMinutes * 1000 * 60 });

  const totalHours = totalDuration.hours || 0;
  const totalMinutes = totalDuration.minutes || 0;

  // get gig category time
  let gigCategoryStart = '';
  let gigCategoryEnd = '';

  if (obj.gig.gig_category) {
    const timeCategory = obj.gig_category_time_occurrence || obj.gig_category_time_occasion;
    const { start_at: startAt, finish_at: finishAt } = timeCategory;

    gigCategoryStart = new Date(`${obj.gig.date}T${startAt}`);
    gigCategoryEnd = new Date(`${obj.gig.date}T${finishAt}`);
  }

  const getDistance = obj.distance === null ? '' : obj.distance;
  const getPlannerDistance = obj.planner_distance === null ? '' : obj.planner_distance;
  const getBillingAgreement = obj.billing_agreement || '';
  const placementEntityId = obj.placement_entity_id || '';
  const defPlacementEntityId = !isEmpty(obj.placementEntities) ? obj.placementEntities[0].id : '';

  return ({
    startTime,
    endTime,
    planedHours,
    planedMinutes,
    breakMoment,
    totalHours,
    totalMinutes,
    deviation,
    sumPlanedMinutes,
    gigCategoryStart,
    gigCategoryEnd,
    distance: getDistance,
    plannerDistance: getPlannerDistance,
    expenses: [...obj.expenses],
    documents: obj.documents || [],
    comments: obj.comments || [],
    billingAgreement: getBillingAgreement,
    placementEntityId: placementEntityId || defPlacementEntityId,
  });
};

/**
 * Prepare shift entity from DB, for insert to form
 * @param {object} gigEntity
 */
export const prepareShiftToForm = (obj) => {
  const startTime = new Date(`${obj.date}T${obj.start_at}`);
  const finishTime = obj.night
    ? new Date(`${obj.date}T${obj.night_finish_at}`)
    : new Date(`${obj.date}T${obj.finish_at}`);

  const attributes = formatCustomAttributes(obj.custom_gig_attributes);

  const gigCategoryInfo = obj.gig_category || {};
  if (!isEmpty(obj.gig_category_time_occasion)) {
    gigCategoryInfo.startTime = new Date(`${obj.date}T${obj.gig_category_time_occasion.start_at}`);
    gigCategoryInfo.finishTime = new Date(`${obj.date}T${obj.gig_category_time_occasion.finish_at}`);
  }

  let preparePause = !isEmpty(obj.timebreak) ? obj.timebreak : '';
  if (typeof preparePause === 'object') {
    const prepareTimebreakMinutes = obj.timebreak?.minutes || 0;
    const prepareTimebreakHours = obj.timebreak?.hours ? obj.timebreak.hours * 60 : 0;
    const totalMinutes = prepareTimebreakHours + prepareTimebreakMinutes;
    preparePause = addMinutes(startOfDay(new Date()), totalMinutes);
  }

  let preparedDateUntil = obj.expire_due_date || '';
  if (preparedDateUntil) {
    preparedDateUntil = preparedDateUntil && isValid(obj.expire_due_date)
      ? obj.expire_due_date
      : new Date(obj.expire_due_date.substring(0, 16));
  }

  const rolesEntity = obj.roles_workers?.map((roleWorkers) => ({
    entityId: uniqueId('entity_'),
    ids: roleWorkers.roles.map((role) => role.id),
    count: roleWorkers.workers_needed,
    workerIds: [],
  })) || [];

  return ({
    title: obj.title,
    location: obj.location,
    organization: obj.organization,
    roleIds: [],
    roleCount: 1,
    editRoleId: '',
    rolesEntity,
    date: new Date(obj.date),
    finishTime,
    startTime,
    hasStartAt: obj.has_start_at,
    hasFinishAt: obj.has_finish_at,
    attributes,
    description: obj.description || '',
    hiddenDesc: obj.hidden_description || '',
    showUntil: !!obj.expire_due_date,
    dateUntil: preparedDateUntil,
    isNight: obj.night,
    project_id: obj.project_id || null,
    documentsData: !isEmpty(obj.documents) ? obj.documents : [],
    favorite: obj.filtering_setting?.favorite || false,
    rating: obj.filtering_setting?.rating || '',
    filterAttributes: {},
    scheduleType: obj.schedule_type,
    open: obj.open,
    workerIds: [],
    worker_data: obj.worker_data,
    timebreak: preparePause,
    gigCategoryInfo,
  });
};

export const prepareFilterAttributes = (workerAttributes = [], attributes = {}) => {
  const filterAttributes = {};
  workerAttributes.forEach((item) => {
    const attr = attributes[item.id];
    if (attr.input_type === 'multiselect') {
      const prepareOptions = attr.options.map((opt, index) => ({
        title: opt,
        value: index,
        propertyId: item.id,
      }));
      const getAddedOption = prepareOptions.find((option) => option.title === item.title);

      const prevFilterAttr = filterAttributes[item.id];
      if (prevFilterAttr) {
        filterAttributes[item.id] = {
          id: item.id,
          title: [
            ...prevFilterAttr.title,
            getAddedOption,
          ],
          value: [
            ...prevFilterAttr.value,
            getAddedOption,
          ]
        };

        return;
      }

      const multiAttribute = {
        id: item.id,
        title: [getAddedOption],
        value: [getAddedOption],
      };

      filterAttributes[item.id] = multiAttribute;
      return;
    }

    filterAttributes[item.id] = item;
  });

  return filterAttributes;
};

export const prepareShiftTitle = (params) => {
  const {
    startText,
    organization,
    location,
    date,
    startTime,
    finishTime,
    isNight,
    role,
  } = params;

  const orgTitle = organization.title ? `${organization.title},` : '';
  const locTitle = location.address ? `${location.address},` : '';
  const roleTitle = role && role.title ? `${role.title},` : '';
  const start = `${format(date, 'dd-MM-yyyy')}, ${format(startTime, 'HH:mm')}`;
  const end = isNight
    ? `${format(addDays(date, 1), 'dd-MM-yyyy')} ${format(finishTime, 'HH:mm')}`
    : ` - ${format(finishTime, 'HH:mm')}`;

  return `${startText} ${orgTitle} ${locTitle} ${roleTitle} ${start} - ${end}`;
};

export const prepareShiftSave = (shift) => {
  const params = {
    organization_id: shift.organization.id,
    gig: {
      status: shift.status,
      location_id: '',
      title: shift.title,
      schedule_type: shift.scheduleType,
      open: shift.open,
    }
  };

  params.gig.roles = [];
  const roleEntityForm = {
    ids: shift.roleIds,
    count: shift.roleCount,
  };

  if (!shift.open) {
    roleEntityForm.worker_ids = shift.workerIds;
  }

  if (!isEmpty(shift.roleIds) && !shift.editRoleId) {
    params.gig.roles.push(roleEntityForm);
  }

  const prepareRolesEntity = shift.selectedRoles.map((selectedRole) => {
    const roleEntity = {
      ids: selectedRole.ids,
      count: selectedRole.count,
    };
    if (!shift.open) {
      roleEntity.worker_ids = selectedRole.workerIds;
    }

    return roleEntity;
  });

  if (!isEmpty(prepareRolesEntity)) {
    params.gig.roles.push(...prepareRolesEntity);
  }

  if (params.gig.roles.length > 1) {
    params.gig.multi_roles = true;
  }

  params.gig.date = format(shift.date, 'yyyy-MM-dd');
  params.gig.start_at = format(shift.startTime, 'HH:mm');
  params.gig.finish_at = format(shift.finishTime, 'HH:mm');

  params.gig.has_start_at = shift.hasStartAt;
  params.gig.has_finish_at = shift.hasFinishAt;

  if (shift.isNight) {
    params.gig.night = shift.isNight;
    params.gig.finish_at = '23:59';
    params.gig.night_date = format(addDays(shift.date, 1), 'yyyy-MM-dd');
    params.gig.night_start_at = '00:00';
    params.gig.night_finish_at = format(shift.finishTime, 'HH:mm');
  }

  if (shift.location.id) {
    params.gig.location_id = shift.location.id;
  }

  if (shift.description) {
    params.gig.description = shift.description;
  }

  if (shift.hiddenDesc) {
    params.gig.hidden_description = shift.hiddenDesc;
  }

  if (shift.timebreak) {
    const startTimeBreak = startOfDay(shift.timebreak);
    const timeBreakData = intervalToDuration({ start: startTimeBreak, end: shift.timebreak });

    params.gig.timebreak = {
      hours: timeBreakData.hours || 0,
      minutes: timeBreakData.minutes || 0,
    };
  }

  if (!isEmpty(shift.documentsData)) {
    params.gig.documents_data = shift.documentsData;
  }

  if (shift.dateUntil) {
    params.gig.expire_due_date = format(shift.dateUntil, 'yyyy-MM-dd HH:mm');
  }

  if (shift.isMultiDay && !isEmpty(shift.multiDays)) {
    params.gig.repeat = {};
    params.gig.repeat.type = 'multiday';
    params.gig.repeat.dates = shift.multiDays
      .filter((multiDay) => !isSameDay(multiDay, shift.date))
      .map((multiDate) => format(multiDate, 'yyyy-MM-dd'));
  }

  if (!shift.isMultiDay && shift.repeat && shift.repeat.show) {
    params.gig.repeat = {};
    params.gig.repeat.type = shift.repeat.type;

    const finishDate = format(shift.repeat.finish, 'yyyy-MM-dd');

    if (shift.repeat.type === globalConst.DAY_STR) {
      if (shift.repeat.endType === globalConst.END_TYPE_ON) {
        params.gig.repeat.finish_at = finishDate;
      }

      if (shift.repeat.endType === globalConst.END_TYPE_AFTER) {
        params.gig.repeat.occurrences = shift.repeat.occurrences;
      }
    }

    if (shift.repeat.type === globalConst.WEEK_STR
      || shift.repeat.type === globalConst.BIWEEKLY_STR
      || shift.repeat.type === globalConst.THREE_WEEKS_STR
      || shift.repeat.type === globalConst.FOUR_WEEKS_STR
      || shift.repeat.type === globalConst.FIVE_WEEKS_STR) {
      params.gig.repeat.days = shift.repeat.days;
      params.gig.repeat.finish_at = finishDate;
    }

    if (shift.repeat.type === globalConst.WEEKDAY_STR) {
      params.gig.repeat.type = globalConst.WEEK_STR;
      params.gig.repeat.days = globalConst.WEEKDAY_ARR;
      params.gig.repeat.finish_at = finishDate;
    }

    if (shift.repeat.acceptability) {
      params.gig.repeat.single_gig_acceptability = true;
    }
  }

  // set custom attributes
  if (!isEmpty(shift.attributes)) {
    const gigAttributes = [];
    Object.keys(shift.attributes).forEach((id) => {
      const attr = shift.attributes[id];
      const isMultiselect = Array.isArray(attr);
      const value = isMultiselect ? attr : attr.title;

      if (value && value instanceof Array) {
        gigAttributes.push({
          custom_attribute_id: id,
          title: !isEmpty(value) ? value.map((item) => item.title) : [],
        });
        return;
      }

      if (value) {
        gigAttributes.push({
          custom_attribute_id: id,
          title: shift.attributes[id].title,
        });
      }
    });

    params.gig.custom_gig_attributes = gigAttributes;
  }

  params.gig.filtering_setting = {};
  params.gig.filtering_setting.favorite = shift.favorite;

  if (shift.rating) {
    params.gig.filtering_setting.rating = shift.rating;
  }

  if (!isEmpty(shift.filterAttributes)) {
    const customAttr = [];
    Object.keys(shift.filterAttributes).forEach((id) => {
      const prepValue = shift.filterAttributes[id].title;
      if (prepValue && Array.isArray(prepValue)) {
        prepValue.forEach((valueAttr) => {
          customAttr.push({
            id: valueAttr.propertyId,
            title: valueAttr.title,
          });
        });
      }

      if (prepValue && !Array.isArray(prepValue)) {
        customAttr.push({
          id,
          title: prepValue,
        });
      }
    });

    params.gig.filtering_setting.custom_attributes = customAttr;
  }

  if (!isEmpty(shift.project)) {
    params.gig.project_id = shift.project.id;
  }

  if (!isEmpty(shift.gigCategoryInfo)) {
    params.gig.gig_category_info = {
      id: shift.gigCategoryInfo.id,
    };

    if (shift.gigCategoryInfo.timeframe_type === globalConst.HOURS_STR) {
      params.gig.gig_category_info.start_at = format(shift.gigCategoryInfo.startTime, 'HH:mm');
      params.gig.gig_category_info.finish_at = format(shift.gigCategoryInfo.finishTime, 'HH:mm');
    }
  }

  return params;
};

export const getShiftTemplate = () => ({
  id: uniqueId('shift'),
  title: '',
  date: new Date(),
  startTime: set(new Date(), { hours: 9, minutes: 0, seconds: 0 }),
  finishTime: set(new Date(), { hours: 18, minutes: 0, seconds: 0 }),
  hasStartAt: true,
  hasFinishAt: true,
  organization: '',
  location: '',
  location_id: '',
  schedule_type: globalConst.AUTO_APPROVE_TYPE,
  roleIds: [],
  roleCount: 1,
  description: '',
  hidden_description: '',
  timebreak: '',
  attributes: {},
  workers: [],
  favorite: true,
  errors: {},
});

export const getWorkerTemplate = () => ({
  id: uniqueId('worker'),
  firstName: '',
  lastName: '',
  email: '',
  roles: [],
  phone: '',
  address: '',
  birthdate: '',
  errors: {},
});

/**
 * Prepare shift to fixing
 * @param {array} data
 */
export const prepareShiftToFixing = (data = []) => {
  const allIds = [];
  const byId = {};

  data.forEach((item) => {
    const errors = {};
    item.errors.forEach(({ field, message }) => {
      if (field === 'start_at') {
        errors.date = message;
      }

      if (field === 'finish_at') {
        errors.date = message;
      }

      if (field === 'gig_role_requirements') {
        errors.roleEntity = message;
      }

      errors[field] = message;
    });

    const parsedDate = (item.gig.date && parse(item.gig.date, 'dd-MM-yyyy', new Date()));
    const date = isValid(parsedDate) ? parsedDate : new Date();
    const formattedDate = format(new Date(), 'yyyy-MM-dd');

    const startTimeStr = item.gig.start_at ? item.gig.start_at : '00:00';
    const startPreparedTime = new Date(`${formattedDate}T${startTimeStr}`);
    const startTime = isValid(startPreparedTime) ? startPreparedTime : new Date(`${formattedDate}T00:00`);

    const finishTimeStr = item.gig.finish_at ? item.gig.finish_at : '23:59';
    const finishPreparedTime = new Date(`${formattedDate}T${finishTimeStr}`);
    const finishTime = isValid(finishPreparedTime) ? finishPreparedTime : new Date(`${formattedDate}T23:59`);

    const shift = {
      id: uniqueId('shift'),
      ...item.gig,
      title: item.gig.title || '',
      date,
      startTime,
      finishTime,
      hasStartAt: !!item.gig.start_at,
      hasFinishAt: !!item.gig.finish_at,
      organization: {
        id: item.organization_id || '',
        title: '',
      },
      location: {
        id: item.gig.location_id || '',
        address: item.gig.location_address || ''
      },
      roleIds: item.gig.roles.ids,
      roleCount: item.gig.roles.count,
      description: item.gig.description || '',
      workers: [],
      errors,
    };

    if (!shift.organization) {
      shift.location = '';
    }

    allIds.push(shift.id);
    byId[shift.id] = shift;
  });

  return { byId, allIds };
};

export const convertWorkerParamsToString = (params) => {
  const extraParams = {};
  extraParams.organization_id = params.organization.id;
  extraParams.by_roles = params.roleIds;

  if (!params.favorite) {
    extraParams.all = true;
  }

  const request = {};
  request.schedule = [];
  request.schedule.start_at = format(params.startTime, 'HH:mm');
  request.schedule.finish_at = format(params.finishTime, 'HH:mm');

  if (params.isNight) {
    request.schedule.night = true;
    request.schedule.finish_at = '23:59';
    request.schedule.night_start_at = '00:00';
    request.schedule.night_finish_at = format(params.finishTime, 'HH:mm');
  }

  const datesParams = {};
  datesParams.schedule = {};
  datesParams.schedule.dates = [];
  datesParams.schedule.dates[0] = {};
  datesParams.schedule.dates[0].date = format(params.date, 'yyyy-MM-dd');

  if (params.isNight) {
    const nightDate = addDays(params.date, 1);
    datesParams.schedule.dates[0].night_date = format(nightDate, 'yyyy-MM-dd');
  }

  const isAssignType = params.scheduleType === 'assign';
  if (isAssignType && params.isMultiDay && !isEmpty(params.multiDays)) {
    params.multiDays.filter((multiDay) => !isSameDay(multiDay, params.date))
      .forEach((item, index) => {
        const nightMultiDate = addDays(item, 1);
        datesParams.schedule.dates[index + 1] = [];
        datesParams.schedule.dates[index + 1].date = format(item, 'yyyy-MM-dd');
        if (params.isNight) {
          datesParams.schedule.dates[index + 1].night_date = format(nightMultiDate, 'yyyy-MM-dd');
        }
      });
  }

  const datesParamsQuery = serializeParamsToQueryString(datesParams);

  if (params.gig_id) {
    request.gig_id = params.gig_id;
  }

  if (params.byName) {
    request.by_name = params.byName;
  }

  if (params.rating) {
    request.by_rating = params.rating;
  }

  if (!isEmpty(params.filterAttributes)) {
    extraParams.by_custom_attrs = [];

    Object.keys(params.filterAttributes).forEach((key) => {
      const { title } = params.filterAttributes[key];

      if (Array.isArray(title)) {
        title.forEach((option) => {
          const optionAttrArr = [];
          optionAttrArr.id = key;
          optionAttrArr.title = option.title;
          extraParams.by_custom_attrs.push(optionAttrArr);
        });
        return;
      }

      const attrArr = [];
      attrArr.id = key;
      attrArr.title = title;
      extraParams.by_custom_attrs.push(attrArr);
    });
  }

  if (params.repeat && params.repeat.show && !params.repeat.acceptability) {
    request.availability_absence = true;
  }

  const paramsUrl = serializeParamsToQueryString(request);
  const paramsExtraUrl = serializeParamsToQueryString(extraParams, true);
  return `${paramsUrl}&${datesParamsQuery}&${paramsExtraUrl}`;
};

export const isIEBrowser = () => {
  const ua = window.navigator.userAgent;
  return /MSIE|Trident/.test(ua);
};

export const getBlob = (content, contentType) => isIEBrowser()
  ? new Blob([new Uint8Array(content)]) : new Blob([content], { type: contentType });

export const saveToDisk = (fileUrl, fileName) => {
  if (isIEBrowser()) {
    const windowCopy = window.open(fileUrl, '_blank');
    windowCopy.document.close();
    windowCopy.document.execCommand('SaveAs', true, fileName || fileUrl);
    windowCopy.close();
    return true;
  }

  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = fileName || 'unknown';
  link.style.display = 'none';
  link.target = '_blank';
  (document.body || document.documentElement).appendChild(link);

  if (typeof link.click === 'function') {
    link.click();
  } else {
    link.target = '_blank';
    const event = document.createEvent('Event');
    event.initEvent('click', true, true);
    link.dispatchEvent(event);
  }

  (window.URL || window.webkitURL).revokeObjectURL(link.href);
};

/**
 * Copies a string to the clipboard. Must be called from within an
 * event handler such as click. May return false if it failed, but
 * this is not always possible. Browser support for Chrome 43+,
 * Firefox 42+, Safari 10+, Edge and IE 10+.
 * IE: The clipboard feature may be disabled by an administrator. By
 * default a prompt is shown the first time the clipboard is
 * used (per session).
 * @text {String} text - The text which have to copy,
*/
export const copyToClipboard = (text) => {
  if (window.clipboardData && window.clipboardData.setData) {
    // IE specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData('Text', text);
  }

  if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const textarea = document.createElement('textarea');
    textarea.textContent = text;
    textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
    document.body.appendChild(textarea);
    textarea.select();

    try {
      document.execCommand('copy'); // Security exception may be thrown by some browsers.
      return Promise.resolve(true);
    } catch (ex) {
      console.warn('Copy to clipboard failed.', ex); // eslint-disable-line
      return Promise.reject(new Error({ err: 'Copy to clipboard failed', ex }));
    } finally {
      document.body.removeChild(textarea);
    }
  }

  return true;
};

/**
 * Get random from array
 * @param {array} arr
 */
export const getRandom = (arr) => arr[Math.floor(Math.random() * arr.length)];

export const getMenuItems = (props) => {
  const {
    tr,
    currentLang,
    planningSearch,
    timeTrackerQuery,
    featureIsTimeTracking,
    featureIsBilling,
    featureIsProjects,
    isExternal,
    isExternalBlocked,
    isInternalBlocked,
    isInternalBlockedFletcher,
    isInternalBlockedSwzzorg,
    isInternalBlockedKiz,
    isYzorgManagers,
  } = props;
  const menuItems = [];

  if (isInternalBlockedFletcher) {
    menuItems.push({
      title: tr.dashboard.menu.team,
      href: `/${currentLang}/workers`,
      slug: 'workers',
      icon: 'usercircle',
      target: ''
    });
  }

  if (isInternalBlockedFletcher || isInternalBlockedSwzzorg) {
    menuItems.push({
      title: tr.dashboard.menu.shifts,
      href: `/${currentLang}/planning-shifts?${planningSearch}`,
      slug: 'planning-shifts',
      icon: 'calendaroutline',
      target: ''
    });
  }

  if (isInternalBlockedFletcher) {
    return menuItems;
  }

  if (featureIsProjects && !isExternal) {
    menuItems.push({
      title: tr.common.projects,
      href: `/${currentLang}/projects`,
      slug: 'projects',
      icon: 'projects',
      target: ''
    });
  }

  if (featureIsTimeTracking && !isInternalBlockedKiz) {
    menuItems.push({
      title: tr.common.timeTracking,
      href: `/${currentLang}/time-tracking${timeTrackerQuery}`,
      slug: 'time-tracking',
      icon: 'time',
      target: ''
    });
  }

  if (featureIsBilling && !isExternal && !isYzorgManagers && !isInternalBlockedSwzzorg && !isInternalBlockedKiz) {
    menuItems.push({
      title: tr.common.billing,
      href: `/${currentLang}/billing`,
      slug: 'billing',
      icon: 'file',
      target: ''
    });
  }

  if (isExternalBlocked || isInternalBlockedSwzzorg) {
    return menuItems;
  }

  if (isExternal) {
    menuItems.unshift({
      title: tr.dashboard.menu.shifts,
      href: `/${currentLang}/planning-shifts?${planningSearch}`,
      slug: 'planning-shifts',
      icon: 'calendaroutline',
      target: ''
    });

    menuItems.push({
      title: tr.common.settings,
      href: `/${currentLang}/settings-main/security`,
      slug: 'settings-main',
      icon: 'cog',
      target: ''
    });

    return menuItems;
  }

  if (!isInternalBlocked) {
    menuItems.push({
      title: tr.dashboard.menu.reporting,
      href: `/${currentLang}/reporting`,
      slug: 'reporting',
      icon: 'reporting',
      target: ''
    });
  }

  menuItems.unshift({
    title: tr.dashboard.menu.shifts,
    href: `/${currentLang}/planning-shifts?${planningSearch}`,
    slug: 'planning-shifts',
    icon: 'calendaroutline',
    target: ''
  });

  menuItems.unshift({
    title: tr.dashboard.menu.team,
    href: `/${currentLang}/workers`,
    slug: 'workers',
    icon: 'usercircle',
    target: ''
  });

  menuItems.unshift({
    title: tr.common.organizations,
    href: `/${currentLang}/organizations`,
    slug: 'organizations',
    icon: 'gridcircle',
    target: ''
  });

  menuItems.push({
    title: tr.common.settings,
    href: `/${currentLang}/settings-main/roles`,
    slug: 'settings-main',
    icon: 'cog',
    target: ''
  });

  return menuItems;
};

/**
 * Get new position after drag and drop items
 */
export const applyDragAndDrop = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;

  if (removedIndex === null && addedIndex === null) {
    return arr;
  }

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    const [removed] = result.splice(removedIndex, 1);
    itemToAdd = removed;
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};

export const getDailyDifference = (date) => {
  const start = new Date();
  const end = new Date(date);
  const diff = parseInt((end.getTime() - start.getTime()) / (24 * 3600 * 1000), 10);
  return diff;
};
